import LayoutPage from './LayoutPage';


const PageInstance = new LayoutPage({
  defaultPath: "deets.com",
  // getServerSideProps: true,
  getStaticProps: true,
  // getStaticProps: async () => {
  //   const cardAttributes = await require('./indexLayout.json');
  //   return { props: { cardAttributes } }
  // },
});


// export const getServerSideProps = PageInstance.getServerSideProps;
export const getStaticProps = PageInstance.getStaticProps;
// export const getStaticPaths = PageInstance.getStaticPaths;


export default PageInstance.LayoutPage;
